import axios from 'axios';
import { API_BASE_URL } from '@/configs';

export const getSMSCodeAPI = async (req) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/sms-code`, req);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginAPI = async (req) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, req);
    return response.data;
  } catch (error) {
    throw error;
  }
};
