import {
  Form,
  Input,
  Button,
  Space,
  Notification,
  Message,
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useStorage from '@/utils/useStorage';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import { getSMSCodeAPI, loginAPI } from '@/apis/auth';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  // const [loginParams, setLoginParams, removeLoginParams] = useStorage('loginParams');

  const t = useLocale(locale);

  // const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  function afterLoginSuccess(params) {
    // 记住密码
    // if (rememberPassword) {
    //   setLoginParams(JSON.stringify(params));
    // } else {
    //   removeLoginParams();
    // }
    // 记录登录状态
    localStorage.setItem('token', params.token);
    localStorage.setItem('userStatus', 'login');
    // 跳转首页
    window.location.href = '/';
  }

  function login(params) {
    setErrorMessage('');
    setLoading(true);
    params = { ...params, type: 'sms' };
    loginAPI(params)
      .then((res) => {
        console.log(res.data);
        if (res.code === 1) {
          afterLoginSuccess(res.data);
        } else {
          Message.error({
            content: res.msg,
            closable: true,
            duration: 5000,
            position: 'bottom',
          });
        }
      })
      .catch((error) => {
        Message.error({
          content: error.toString(),
          closable: true,
          duration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // axios
    //   .post('/api/user/login', params)
    //   .then((res) => {
    //     const { status, msg } = res.data;
    //     if (status === 'ok') {
    //       afterLoginSuccess(params);
    //     } else {
    //       setErrorMessage(msg || t['login.form.login.errMsg']);
    //     }
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }

  const getSMSCode = () => {
    const phoneNumber = formRef.current.getFieldValue('phone_number');

    if (!phoneNumber) {
      Message.error({
        content: '手机号未填写',
        closable: true,
        duration: 5000,
      });
      return;
    }

    const params = {
      phone_number: phoneNumber,
    };
    setCodeLoading(true);
    setCountdown(60);

    getSMSCodeAPI(params)
      .then((res) => {
        if (res.code === 1) {
          setTimeout(() => {
            const timer = setInterval(() => {
              setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                  clearInterval(timer);
                  return 0;
                }
                return prevCountdown - 1;
              });
            }, 1000);
          }, 1000);
        } else {
          setCountdown(0);
          Message.error({ content: res.msg, closable: true, duration: 5000 });
        }
      })
      .catch((error) => {
        console.log(`getSMSCodeAPI: ${error}`);
        setCountdown(0);
        Message.error({
          content: error.toString(),
          closable: true,
          duration: 5000,
        });
      })
      .finally(() => {
        setCodeLoading(false);
      });
  };

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  // 读取 localStorage，设置初始值
  useEffect(() => {
    // const rememberPassword = !!loginParams;
    // setRememberPassword(rememberPassword);
    // if (formRef.current && rememberPassword) {
    //   const parseParams = JSON.parse(loginParams);
    //   formRef.current.setFieldsValue(parseParams);
    // }
    // }, [loginParams]);
  }, []);

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>佰食馥厨房管理系统</div>
      {/*<div className={styles['login-form-sub-title']}>*/}
      {/*  {t['login.form.title']}*/}
      {/*</div>*/}
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form className={styles['login-form']} layout="vertical" ref={formRef}>
        <Form.Item
          field="phone_number"
          rules={[
            {
              required: true,
              match:
                /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9]|(19[0-9])))\d{8}$/,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input prefix={<IconUser />} placeholder="请输入手机号" />
        </Form.Item>
        <div className="flex flex-row">
          <Form.Item
            field="code"
            rules={[
              {
                required: true,
                match: /^\d{6}$/,
                message: '请输入正确的验证码',
              },
            ]}
          >
            <Input
              prefix={<IconLock />}
              placeholder="请输入验证码"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Button
            style={{ width: '40%' }}
            type="primary"
            onClick={getSMSCode}
            loading={codeLoading}
            disabled={countdown > 0}
          >
            {countdown > 0 ? `${countdown} s` : '获取验证码'}
          </Button>
        </div>
        <Space size={16} direction="vertical">
          {/*<div className={styles['login-form-password-actions']}>*/}
          {/*  <Checkbox checked={rememberPassword} onChange={setRememberPassword}>*/}
          {/*    {t['login.form.rememberPassword']}*/}
          {/*  </Checkbox>*/}
          {/*  <Link>{t['login.form.forgetPassword']}</Link>*/}
          {/*</div>*/}
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            登录
          </Button>
          {/*<Button*/}
          {/*  type="text"*/}
          {/*  long*/}
          {/*  className={styles['login-form-register-btn']}*/}
          {/*>*/}
          {/*  {t['login.form.register']}*/}
          {/*</Button>*/}
        </Space>
      </Form>
    </div>
  );
}
